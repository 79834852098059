<template>
  <!-- map -->
  <div class="px-4">
    <ion-grid class="ion-no-padding">
      <ion-list mode="ios" lines="none">
        <!-- Start Fist Name -->
        <div class="title">{{ $t('newcustomer.person_in_charge') }}</div>
        <div>
          <ion-item
            :class="[
              'px-4',
              form.firstName.isError
                ? 'ion-item-danger'
                : !form.firstName.isError && form.firstName.value
                ? 'ion-item-primary'
                : ''
            ]"
          >
            <ion-label
              class="text-title"
              :color="
                form.firstName.isError
                  ? 'danger'
                  : !form.firstName.isError && form.firstName.value
                  ? 'primary'
                  : 'tertiary'
              "
              position="stacked"
              >{{ $t('first_name') }}</ion-label
            >
            <ion-input
              :placeholder="$t('key_contact.enter_first_name')"
              ref="companyName"
              v-model.trim="form.firstName.value"
              @ionInput="handleValidateForm('firstName')"
            ></ion-input>
          </ion-item>
          <ion-text v-if="form.firstName.isError">
            <div class="pl-4 py-1 px-4 fs-12px text-danger">
              {{
                $t(
                  `${
                    form.firstName.value.length === 0
                      ? 'first_name_cannot_be_empty'
                      : 2 > form.firstName.value.length
                      ? 'first_name_must_be_at_least_2_characters'
                      : ''
                  }`
                )
              }}
            </div>
          </ion-text>
        </div>
        <!-- End Fist Name -->

        <!-- Start Last Name -->
        <div>
          <ion-item :class="['px-4', form.lastName.value ? 'ion-item-primary' : '']">
            <ion-label class="text-title" :color="form.lastName.value ? 'primary' : ''" position="stacked">{{
              $t('last_name')
            }}</ion-label>
            <ion-input
              :placeholder="$t('key_contact.enter_last_name')"
              ref="companyName"
              v-model.trim="form.lastName.value"
            ></ion-input>
          </ion-item>
        </div>
        <!-- End Last Name -->

        <!-- Start Phone -->
        <div>
          <div
            class="mt-3 px-4"
            :style="
              form.phone.isError
                ? 'border-bottom: 1px solid red'
                : !form.phone.isError && form.phone.value
                ? 'border-bottom: 1px solid #04565A'
                : 'border-bottom: 1px solid rgba(0, 0, 0, 0.1)'
            "
          >
            <ion-label
              class="text-title"
              :color="
                form.phone.isError
                  ? 'danger'
                  : !form.phone.isError && form.phone.value
                  ? 'primary'
                  : 'tertiary'
              "
              position="stacked"
              >{{ $t('phone') }}</ion-label
            >
            <div class="d-flex align-center">
              <vue-tel-input
                v-model.trim="form.phone.value"
                mode="national"
                enabledCountryCode
                enabledFlags
                validCharactersOnly
                pattern="[0-9]*$"
                styleClasses="no-border	no-box-shadow"
                ref="phone-field"
                inputId="phone-field"
                disabledFetchingCountry
                :onlyCountries="getPreferedCountryCode()"
                :autoDefaultCountry="false"
                :defaultCountry="originCode"
                :autoFormat="false"
                @on-input="onlyNumber"
                @keypress="validatePhoneNumberInput"
                :inputOptions="{
                  type: 'tel',
                  maxlength: 16,
                  placeholder: $t('mobile_number')
                }"
                @country-changed="countryChange"
              />
            </div>
          </div>
          <ion-text v-if="form.phone.isError">
            <div class="pl-4 py-1 px-4 fs-12px text-danger">
              {{ $t('phone_number_cannot_be_empty') }}
            </div>
          </ion-text>
          <ion-text v-if="isPhoneNumberAlreadyExist">
            <div class="pl-4 py-1 px-4 fs-12px text-danger">
              {{ $t('phone_number_already_used_by_another_pic') }}
            </div>
          </ion-text>
        </div>
        <!-- End Phone -->
        <!-- Start Person Type -->
        <div>
          <ion-item
            :class="[
              'pl-4',
              form.personType.isError
                ? 'ion-item-danger'
                : !form.personType.isError && form.personType.value
                ? 'ion-item-primary'
                : ''
            ]"
          >
            <ion-label
              class="ion-mb text-title"
              :color="
                form.personType.isError
                  ? 'danger'
                  : !form.personType.isError && form.personType.value
                  ? 'primary'
                  : 'tertiary'
              "
              position="stacked"
              >{{ $t('person_type') }}</ion-label
            >
            <ion-select
              mode="ios"
              name="personType"
              v-model="form.personType.value"
              placeholder="Select person type"
              @ionChange="handleValidateForm('personType')"
              :okText="$t('OK')"
              :cancelText="$t('cancel')"
            >
              <ion-select-option v-for="(data, index) in personType" :key="index" :value="data">
                {{ data }}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <ion-text>
            <div v-if="form.personType.isError" class="pl-4 py-1 px-4 fs-12px text-danger">
              {{ $t('Person_type_cannot_be_empty') }}
            </div>
          </ion-text>
        </div>

        <!-- Start Email -->
        <div v-if="!isFormSubBuyer">
          <ion-item
            :class="[
              'px-4',
              form.email.isError
                ? 'ion-item-danger'
                : !form.email.isError && form.email.value
                ? 'ion-item-primary'
                : ''
            ]"
          >
            <ion-label
              class="text-title"
              :color="
                form.email.isError
                  ? 'danger'
                  : !form.email.isError && form.email.value
                  ? 'primary'
                  : 'tertiary'
              "
              position="stacked"
              >{{ $t('email') + ' ' + $t('Optional') }}</ion-label
            >
            <ion-input
              placeholder="hello@example.com"
              ref="companyName"
              v-model.trim="form.email.value"
              @ionInput="handleValidateForm('email')"
            ></ion-input>
          </ion-item>
          <ion-text v-if="form.email.isError">
            <div class="pl-4 py-1 fs-12px text-danger">
              {{ $t('this_field_must_be_a_valid_email') }}
            </div>
          </ion-text>
        </div>
        <!-- End Email -->

        <!-- Start Email Requires -->
        <div v-if="isFormSubBuyer">
          <ion-item
            :class="[
              'px-4',
              form.emailRequired.isError
                ? 'ion-item-danger'
                : !form.emailRequired.isError && form.emailRequired.value
                ? 'ion-item-primary'
                : ''
            ]"
          >
            <ion-label
              class="text-title"
              :color="
                form.emailRequired.isError
                  ? 'danger'
                  : !form.emailRequired.isError && form.emailRequired.value
                  ? 'primary'
                  : 'tertiary'
              "
              position="stacked"
              >{{ $t('email') }}</ion-label
            >
            <ion-input
              placeholder="hello@example.com"
              ref="companyName"
              v-model.trim="form.emailRequired.value"
              @ionInput="handleValidateForm('emailRequired')"
            ></ion-input>
          </ion-item>
          <ion-text v-if="form.emailRequired.isError">
            <div class="pl-4 py-1 fs-12px text-danger">
              <!-- {{ $t('this_field_must_be_a_valid_email') }}
              {{ $t('updateProfile.email_cannot_be_empty') }} -->
              {{
                form.emailRequired.length == 0
                  ? $t('updateProfile.email_cannot_be_empty')
                  : $t('this_field_must_be_a_valid_email')
              }}
            </div>
          </ion-text>
        </div>
        <!-- End Email -->
        <!-- Start Email -->
        <div v-if="isFormSubBuyer">
          <ion-item
            :class="[
              'px-4',
              form.password.isError
                ? 'ion-item-danger'
                : !form.password.isError && form.password.value
                ? 'ion-item-primary'
                : ''
            ]"
          >
            <ion-label
              class="text-title"
              :color="
                form.password.isError
                  ? 'danger'
                  : !form.password.isError && form.password.value
                  ? 'primary'
                  : 'tertiary'
              "
              position="stacked"
              >{{ $t('set_password') }}</ion-label
            >
            <ion-input
              :placeholder="$t('set_password_for_sub_buyer')"
              ref="companyName"
              v-model.trim="form.password.value"
              type="password"
              @ionInput="handleValidateForm('password')"
            ></ion-input>
          </ion-item>
          <ion-text v-if="form.password.isError">
            <div class="pl-4 py-1 fs-12px text-danger">
              {{ $t('password_must_be_at_least_6_characters') }}
            </div>
          </ion-text>
        </div>
        <!-- End Email -->

        <!-- Start Confirm Password -->
        <div v-if="isFormSubBuyer">
          <ion-item
            :class="[
              'px-4',
              form.passwordConfirm.isError
                ? 'ion-item-danger'
                : !form.passwordConfirm.isError && form.passwordConfirm.value
                ? 'ion-item-primary'
                : ''
            ]"
          >
            <ion-label
              class="text-title"
              :color="
                form.passwordConfirm.isError
                  ? 'danger'
                  : !form.passwordConfirm.isError && form.passwordConfirm.value
                  ? 'primary'
                  : 'tertiary'
              "
              position="stacked"
              >{{ $t('confirm_password_new') }}</ion-label
            >
            <ion-input
              :placeholder="$t('re_enter_password')"
              ref="companyName"
              type="password"
              v-model.trim="form.passwordConfirm.value"
              @ionInput="handleValidateForm('passwordConfirm')"
            ></ion-input>
          </ion-item>
          <ion-text v-if="form.passwordConfirm.isError">
            <div class="pl-4 py-1 fs-12px text-danger">
              {{ $t('validation.confirm_password') }}
            </div>
          </ion-text>
        </div>
        <!-- End Email -->

        <!-- StartCustomerProfile -->
        <div>
          <ion-item
            :class="[
              'pl-4',
              form.language.isError
                ? 'ion-item-danger'
                : !form.language.isError && form.language.value
                ? 'ion-item-primary'
                : ''
            ]"
          >
            <ion-label
              class="ion-mb text-title"
              :color="
                form.language.isError
                  ? 'danger'
                  : !form.language.isError && form.language.value
                  ? 'primary'
                  : 'tertiary'
              "
              position="stacked"
              >{{ $t('language_preference') }}</ion-label
            >
            <ion-select
              mode="ios"
              name="profile"
              v-model="form.language.value"
              :okText="$t('OK')"
              :cancelText="$t('cancel')"
            >
              <ion-select-option v-for="(data, index) in language" :key="index" :value="data.key">
                {{ data.value }}
              </ion-select-option>
            </ion-select>
          </ion-item>
        </div>
        <div class="form-notification px-4">
          <ion-text>{{ $t('newcustomer.notifications') }}</ion-text>
        </div>

        <!-- start btn -->
        <div>
          <ion-button expand="block" mode="md" @click="onAddPic" :disabled="!isValidToSave">{{
            $t('save')
          }}</ion-button>
        </div>
        <!-- end btn -->
      </ion-list>
    </ion-grid>

    <ion-loading
      mode="ios"
      :is-open="isOpenRefLoading"
      cssClass="my-custom-class"
      :message="`${$t('please_wait')}. . .`"
      :duration="timeout"
      @didDismiss="setOpenLoading(false)"
    >
    </ion-loading>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import { apolloClient } from '@/main';
import { addPerson, addSubBuyer } from '@/modules/b2b/services/graphql';
import { cipher } from '@/modules/b2b/services/libs/cipher';
import {
  findObjectByKey,
  getDefaultCountryCode,
  getPreferedCountryCode,
  getRegexEmail
} from '@/modules/b2b/services/libs/helper';
import { checkPersonByPhoneNumber, getPicConnectByPinConfig } from '@/services/shared/graphql';
import { picDefaultLanguages } from '@/services/shared/helper/countries';
import { alertController } from '@ionic/vue';
import { searchOutline } from 'ionicons/icons';
import debounce from 'lodash.debounce';
import { defineComponent } from 'vue';
import { VueTelInput } from 'vue-tel-input';
import './styles/VueTelInput.scss';

export default defineComponent({
  name: 'AddressForm',
  components: {
    VueTelInput
  },
  props: ['customerDetailsEdit', 'listEditPersonInCharge', 'user', 'isKeyContact', 'originCode'],
  data() {
    return {
      isOpenRef: false,
      isOpenRefLoading: false,
      countryCode: '',
      form: {
        firstName: {
          isError: false,
          value: ''
        },
        lastName: {
          value: ''
        },
        phone: {
          isError: false,
          value: ''
        },
        personType: {
          isError: false,
          value: ''
        },
        email: {
          isError: false,
          value: ''
        },
        emailRequired: {
          isError: false,
          value: ''
        },
        password: {
          isError: false,
          value: ''
        },
        passwordConfirm: {
          isError: false,
          value: ''
        },
        language: {
          isError: false,
          value: 'en-US'
        }
      },
      language: picDefaultLanguages,
      personType: ['Operation', 'Finance'],
      isFormSubBuyer: false,
      encrypt: cipher(),
      enablePicConnectByPin: false,
      isAddedUserExist: false,
      isPhoneNumberAlreadyExist: false,
      isValidToSave: false
    };
  },
  inject: ['$storage'],
  setup() {
    return {
      searchOutline,
      findObjectByKey,
      picDefaultLanguages,
      getPreferedCountryCode
    };
  },
  watch: {
    countryCode() {
      this.countryCode == 86
        ? (this.form.language.value = 'zh-CN')
        : this.countryCode == 60
        ? (this.form.language.value = 'ms-MY')
        : this.countryCode == 62
        ? (this.form.language.value = 'id-ID')
        : (this.form.language.value = 'en-US');
    },
    'form.personType.value'() {
      if (this.form.personType.value === 'Sub-buyer') {
        this.isFormSubBuyer = true;
        this.handleResetValidate();
      } else {
        this.isFormSubBuyer = false;
        this.handleResetValidate();
      }
    },
    'form.phone.value'() {
      this.handleValidateForm('phone');
    }
  },
  async mounted() {
    if (!this.isKeyContact) {
      this.personType.unshift('Key Contact');
    }
    if (this.listEditPersonInCharge) {
      this.personType;
    }
    if (this.listEditPersonInCharge) {
      await this.handleGetDataEditFromProps();
      this.form.firstName.value = this.listEditPersonInCharge.first_name;
      this.form.lastName.value = this.listEditPersonInCharge.last_name || '';
      this.form.email.value = this.listEditPersonInCharge.email;
      this.form.personType.value = this.listEditPersonInCharge.position;
      this.form.language.value = this.listEditPersonInCharge.language || 'en-US';
      this.$nextTick(() => {
        this.form.language.value = this.listEditPersonInCharge.language || 'en-US';
      });
    }
    await this.getPicConnectByPinConfig();
  },

  methods: {
    async handleGetDataEditFromProps() {
      let data = this.listEditPersonInCharge.phone;
      const cutPhone =
        data.indexOf(' ') > -1 ? data.split(' ')[1] : data.length > 8 ? data.substring(2, data.length) : data;
      this.$nextTick(() => {
        this.splitPhoneAndCountryCode(data, cutPhone);
      });
    },
    splitPhoneAndCountryCode(fullPhoneNumber, phone) {
      this.countryCode =
        fullPhoneNumber.indexOf(' ') > -1
          ? fullPhoneNumber.split(' ')[0]
          : fullPhoneNumber.length > 8
          ? fullPhoneNumber.substring(0, 2)
          : getDefaultCountryCode();
      const country = this.findObjectByKey(
        this.$refs['phone-field'].allCountries, // List country
        'dialCode', // Key to find
        this.countryCode // value to find
      );

      this.defaultCountry = country ? country.iso2 : null;
      this.$refs['phone-field'].choose(this.defaultCountry);
      this.form.phone.value = phone;
    },

    setOpen(params) {
      this.isOpenRef = params;
    },
    handleResetValidate() {
      for (const i in this.form) {
        this.form[i].isError = false;
      }
    },
    setOpenLoading(params) {
      this.isOpenRefLoading = params;
    },
    countryChange(val) {
      this.defaultCountry = val.iso2;
      this.countryCode = val.dialCode || getDefaultCountryCode();
    },
    onlyNumber(val) {
      // Get the current input value
      let input = val.toString();

      // Remove any leading zeros and decimal point
      input = input.replace(/^0+|^,|^\.+/g, '');

      // Remove any non-numeric characters
      input = input.replace(/[^\d.,]/g, '');
      input = input.replace(/[.,]/g, '');

      this.form.phone.value = input;
    },

    async validatePhoneNumberInput(event) {
      // block decimal and only allow number
      let keyCode = event.keyCode ? event.keyCode : event.which;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }

      // check if user phone number is already exist
      if (this.form.phone.value.length > 5) {
        await this.checkDuplicatePhoneNumber();
      }
    },
    validateForm(params) {
      if (params === 'firstName') {
        const lengthFistName = this.form.firstName.value.length;
        lengthFistName === 0 || lengthFistName < 2
          ? (this.form.firstName.isError = true)
          : (this.form.firstName.isError = false);
      } else if (params === 'email') {
        this.form.email.value && !getRegexEmail().test(this.form.email.value)
          ? (this.form.email.isError = true)
          : (this.form.email.isError = false);
      } else if (params === 'phone') {
        this.form.phone.value.length === 0
          ? (this.form.phone.isError = true)
          : (this.form.phone.isError = false);
      } else if (params === 'personType') {
        this.form.personType.value.length === 0
          ? (this.form.personType.isError = true)
          : (this.form.personType.isError = false);
      } else if (params === 'emailRequired') {
        const data = this.form.emailRequired.value.length;
        if (data.length === 0) {
          this.form.emailRequired.isError = true;
        } else if (!getRegexEmail().test(this.form.emailRequired.value)) {
          this.form.emailRequired.isError = true;
        } else {
          this.form.emailRequired.isError = false;
        }
      } else if (params === 'password') {
        if (this.form.password.value.length < 6) {
          this.form.password.isError = true;
        } else {
          this.form.password.isError = false;
        }
      } else {
        if (this.form.passwordConfirm.value.length === 0) {
          this.form.passwordConfirm.isError = true;
        } else if (this.form.passwordConfirm.value !== this.form.password.value) {
          this.form.passwordConfirm.isError = true;
        } else {
          this.form.passwordConfirm.isError = false;
        }
      }
      this.checkFormValidation();
    },
    async handleAddPersonInCharge() {
      try {
        this.setOpenLoading(true);
        let isAddPersonInCharge = true;
        if (!this.isFormSubBuyer) {
          const params = ['firstName', 'email', 'phone', 'personType', 'email'];
          for (const data of params) {
            this.validateForm(data);
          }
          for (var i in this.form) {
            if (this.form[i].isError === true) {
              isAddPersonInCharge = false;
              break;
            }
          }
          if (isAddPersonInCharge) {
            let data = {
              active: true,
              country_code: `${this.countryCode}`,
              customer_id: this.customerDetailsEdit.id,
              email: this.form.email.value,
              first_name: this.form.firstName.value,
              last_name: this.form.lastName.value,
              phone: this.form.phone.value,
              position: this.form.personType.value,
              user_id: this.user.id,
              language: this.form.language.value,
              tenant_id: this.user.tenant.id
            };
            if (this.listEditPersonInCharge) {
              data = {
                ...data,
                id: this.listEditPersonInCharge.id
              };
            }
            const res = await apolloClient.query({
              query: addPerson,
              variables: {
                item: data
              }
            });
            if (res?.errors) {
              this.presentAlert(res.errors[0].message);
              this.setOpenLoading(false);
              return;
            } else {
              this.setOpenLoading(false);
              this.isAddedUserExist = res.data.addPerson?.is_user_exist;
              if (this.enablePicConnectByPin && res.data.addPerson?.send_invitation_sms) {
                this.presentAlertAfterAddPersonInCharge();
                return;
              }
              this.$emit('handleReloadComponent');
            }
          }
          this.setOpenLoading(false);
        } else {
          const params = ['firstName', 'emailRequired', 'phone', 'personType', 'password', 'passwordConfirm'];
          for (const data of params) {
            this.validateForm(data);
          }
          for (var k in this.form) {
            if (this.form[k].isError === true) {
              isAddPersonInCharge = false;
              break;
            }
          }
          if (isAddPersonInCharge) {
            await apolloClient.mutate({
              mutation: addSubBuyer,
              variables: {
                user_type_id: 12, // User type for sub buyer = 12
                buyer_type: 1,
                active: true,
                first_name: this.form.firstName.value,
                last_name: this.form.lastName.value,
                mobile: `${this.countryCode}${this.form.phone.value}`,
                email: this.form.emailRequired.value,
                password: this.encrypt(this.form.password.value),
                language: this.form.language.value,
                country_id: this.user.country.id,
                customer_id: this.customerDetailsEdit.id
              }
            });
            this.setOpenLoading(false);
            this.$emit('handleReloadComponent');
          }
          this.setOpenLoading(false);
        }
      } catch (e) {
        this.presentAlert(e);
      }
    },

    handleValidateForm: debounce(function (params) {
      this.validateForm(params);
    }, 500),

    //show message error
    async presentAlert(params) {
      this.setOpenLoading(false);
      const alert = await alertController.create({
        mode: 'ios',
        cssClass: 'my-custom-class',
        header: this.$t('alert_error_header'),
        message: params,
        buttons: [this.$t('OK')]
      });
      await alert.present();
    },

    async getPicConnectByPinConfig() {
      try {
        const res = await apolloClient.query({
          query: getPicConnectByPinConfig
        });
        if (res?.data) {
          this.enablePicConnectByPin = res.data.getPicConnectByPinConfig;
        }
      } catch (error) {
        console.error(error);
      }
    },

    async onAddPic() {
      if (this.enablePicConnectByPin) {
        await this.presentAlertBeforeAddPersonInCharge();
      } else {
        await this.handleAddPersonInCharge();
      }
    },

    async presentAlertBeforeAddPersonInCharge() {
      const phoneNumberWithCountryCode = `+${this.countryCode} ${this.form.phone.value}`;

      const alert = await alertController.create({
        mode: 'ios',
        cssClass: 'my-custom-class',
        message: this.$t('six_digits_pin_code_will_be_sent', { phone: phoneNumberWithCountryCode }),
        buttons: [{ text: this.$t('OK'), handler: () => this.handleAddPersonInCharge() }]
      });
      await alert.present();
    },

    async presentAlertAfterAddPersonInCharge() {
      const userName = `${this.form.firstName.value} ${
        this.form.lastName.value ? this.form.lastName.value : ''
      }`;

      const message = this.isAddedUserExist
        ? this.$t('add_pic_with_existing_user_message', { user_name: userName })
        : this.$t('add_pic_with_non_existing_user_message', { user_name: userName });
      const alert = await alertController.create({
        mode: 'ios',
        cssClass: 'my-custom-class',
        message,
        buttons: [{ text: this.$t('OK'), handler: () => this.$emit('handleReloadComponent') }]
      });
      await alert.present();
    },

    checkFormValidation() {
      if (
        this.form.firstName.value.length > 0 &&
        this.form.firstName.value.length >= 2 &&
        this.form.phone.value.length > 0 &&
        this.form.personType.value.length > 0
      ) {
        this.isValidToSave = true;
      } else {
        this.isValidToSave = false;
      }
    },

    checkDuplicatePhoneNumber: debounce(async function () {
      const phoneNumberWithCountryCode = `${this.countryCode}${this.form.phone.value}`;
      try {
        const res = await apolloClient.query({
          query: checkPersonByPhoneNumber,
          variables: {
            customerId: this.customerDetailsEdit.id,
            phoneNumber: phoneNumberWithCountryCode
          }
        });
        if (res?.data?.checkPersonByPhoneNumber?.id) {
          this.isPhoneNumberAlreadyExist = true;
          this.isValidToSave = false;
        } else {
          this.isPhoneNumberAlreadyExist = false;
        }
      } catch (error) {
        this.presentAlert(error);
      }
    }, 500)
  }
});
</script>
<style src="./styles/ModalAddPersonInCharge.scss" lang="scss" scoped></style>
